import React, { useState } from "react";
import { Box, Card, Tabs, Tab } from "@mui/material";
import NRWDashboard from "../components/NRW2/NRWDashboard";
import NRWManagement from "../components/NRW2/Metering";
import NRWMap from "../components/NRW2/NRWMap";
import DMAMeters from "../components/NRW2/DMAMeters";
import Metering2 from "../components/NRW2/Metering2";
import ProductionMeter from "../components/Operations/ProductionMeter";
import DMAAnalysis from "../components/NRW2/DMAAnalysis2";

const selectList = [
  "Dashboard",
  "Interventions",
  "DMA Analysis",
  "DMA Readings",
  "Production Readings",
];

export default function NRW() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ marginTop: 6 }} disableGutters>
      <Card
        sx={{
          p: 2,
          borderRadius: 5,
          boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
          minHeight: "90vh",
          display: "grid",
          gridTemplateRows: "auto 1fr",
        }}
      >
        {/* Tabs for switching views */}
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={{
            marginBottom: 2,
            borderBottom: 1,
            borderColor: "divider",
          }}
          aria-label="NRW tabs"
        >
          {selectList.map((item, index) => (
            <Tab
              key={index}
              label={item}
              sx={{ textTransform: "capitalize" }}
            />
          ))}
        </Tabs>

        {/* Conditional rendering of components based on selected tab */}
        <Box>{selectedTab === 0 && <NRWDashboard />}</Box>
        <Box>{selectedTab === 1 && <Metering2 />}</Box>
        <Box>{selectedTab === 2 && <DMAAnalysis />}</Box>
        <Box>{selectedTab === 3 && <DMAMeters />}</Box>
        <Box>{selectedTab === 4 && <ProductionMeter />}</Box>
      </Card>
    </Box>
  );
}
