import React, { useState } from "react";
import { ButtonGroup, Button, Box, Divider, Card } from "@mui/material";
import NetworksMaps from "../components/Map/NetworksMap";

export default function MyMap() {
  const [selectedNetwork, setSelectedNetwork] = useState("Water Network");

  const handleNetworkChange = (network) => {
    setSelectedNetwork(network);
  };

  return (
    <Box sx={{ marginTop: 8 }} disableGutters>
      <Card
        sx={{
          p: 2,
          borderRadius: 5,
          boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
          height: "100vh",
          display: "grid",
          gridTemplateRows: "auto auto 1fr",
        }}
      >
        <ButtonGroup
          variant="contained"
          sx={{
            boxShadow: "none",
          }}
          aria-label="network toggle buttons"
        >
          <Button
            onClick={() => handleNetworkChange("Water Network")}
            variant={
              selectedNetwork === "Water Network" ? "contained" : "outlined"
            }
            sx={{ textTransform: "capitalize" }}
          >
            Water Network
          </Button>
          <Button
            onClick={() => handleNetworkChange("Sewer Network")}
            variant={
              selectedNetwork === "Sewer Network" ? "contained" : "outlined"
            }
            sx={{ textTransform: "capitalize" }}
          >
            Sewer Network
          </Button>
          <Button
            onClick={() => handleNetworkChange("New Connections")}
            variant={
              selectedNetwork === "New Connections" ? "contained" : "outlined"
            }
            sx={{ textTransform: "capitalize" }}
          >
            New Connections
          </Button>
        </ButtonGroup>
        <Divider sx={{ my: 1 }} />

        <Box sx={{ position: "relative" }}>
          {selectedNetwork === "Water Network" && (
            <NetworksMaps
              legendItems={[
                {
                  label: "Customers Meters",
                  stroke: "white",
                  fill: "#48CFCB",
                  radius: 6,
                  width: 2,
                  table: "CustomerMeters",
                },
                {
                  label: "Valves",
                  stroke: "white",
                  fill: "#ED3EF7",
                  radius: 4,
                  width: 2,
                  table: "Valves",
                },
                {
                  label: "Master Meters",
                  stroke: "white",
                  fill: "#0D7C66",
                  radius: 8,
                  width: 2,
                  table: "MasterMeters",
                },
                {
                  label: "Bulk Meters",
                  stroke: "white",
                  fill: "#387F39",
                  radius: 8,
                  width: 2,
                  table: "BulkMeters",
                },
                {
                  label: "Tanks",
                  stroke: "white",
                  fill: "#1E2A5E",
                  radius: 10,
                  width: 2,
                  table: "Tanks",
                },
                {
                  label: "Offtakers",
                  stroke: "white",
                  fill: "#697565",
                  radius: 4,
                  width: 2,
                  table: "Offtakers",
                },
                {
                  label: "Water Pipes",
                  stroke: "#229799",
                  fill: "#229799",
                  radius: 4,
                  width: 2,
                  table: "WaterPipes",
                },
              ]}
            />
          )}
          {selectedNetwork === "Sewer Network" && (
            <NetworksMaps
              legendItems={[
                {
                  label: "Customer Chambers",
                  stroke: "white",
                  fill: "#D95F59",
                  radius: 6,
                  width: 2,
                  table: "CustomerChambers",
                },
                {
                  label: "Connection Chambers",
                  stroke: "white",
                  fill: "#522258",
                  radius: 6,
                  width: 2,
                  table: "ConnectionChambers",
                },
                {
                  label: "Manholes",
                  stroke: "white",
                  fill: "#914F1E",
                  radius: 6,
                  width: 2,
                  table: "Manholes",
                },
                {
                  label: "Sewer Lines",
                  stroke: "#DEAC80",
                  fill: "#DEAC80",
                  radius: 4,
                  width: 2,
                  table: "SewerLines",
                },
                {
                  label: "Main Sewer Line",
                  stroke: "#914F1E",
                  fill: "#914F1E",
                  radius: 4,
                  width: 4,
                  table: "SewerMainTrunks",
                },
                {
                  label: "Treatment Plant",
                  stroke: "#4535C1",
                  fill: "#4535C1",
                  radius: 4,
                  width: 2,
                  table: "TreatmentPlants",
                },
              ]}
            />
          )}
          {selectedNetwork === "New Connections" && (
            <NetworksMaps
              legendItems={[
                {
                  label: "New Water Connections",
                  stroke: "#E68369",
                  fill: "#508D4E",
                  radius: 6,
                  table: "NewWaterConnections",
                },
                {
                  label: "New Sanitation Connections",
                  stroke: "orange",
                  fill: "orange",
                  radius: 6,
                  table: "NewSanitationConnections",
                },
                {
                  label: "New Consumer Line",
                  stroke: "blue",
                  fill: "blue",
                  radius: 6,
                  table: "ConsumerLines",
                },
                {
                  label: "New Customer Line",
                  stroke: "grey",
                  fill: "grey",
                  radius: 6,
                  table: "CustomerLines",
                },
              ]}
            />
          )}
        </Box>
      </Card>
    </Box>
  );
}
