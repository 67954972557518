import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import { saveAs } from "file-saver";
import { DataGrid } from "@mui/x-data-grid";

export default function Data(props) {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchColumn, setSearchColumn] = useState("");
  const [availableColumns, setAvailableColumns] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null); // State for dialog
  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog visibility

  useEffect(() => {
    fetchData();
  }, [props.url, currentPage]);

  const fetchData = () => {
    setLoading(true);
    setData(null);

    let apiUrl = `/api/${props.url}/paginated/${(currentPage - 1) * 12}`;
    if (searchTerm && searchColumn) {
      apiUrl = `/api/${
        props.url
      }/paginated/search/${searchColumn}/${searchTerm}/${
        (currentPage - 1) * 12
      }`;
    }

    fetch(apiUrl, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false);

        // Set available columns for the search dropdown
        if (data && data.data && data.data.length > 0) {
          const columns = Object.keys(data.data[0]).filter(
            (key) =>
              key !== "geom" &&
              key !== "ID" &&
              key !== "createdAt" &&
              key !== "updatedAt" &&
              key !== "User" &&
              key !== "Coordinates"
          );
          setAvailableColumns(columns);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchData();
  };

  const handleRowClick = (row) => {
    setSelectedRow(row.row);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedRow(null);
  };

  const handleDownloadCSV = () => {
    if (!data || !data.data || data.data.length === 0) return;

    const csvHeaders = Object.keys(data.data[0]);

    const csvContent = [
      csvHeaders.join(","), // Add headers
      ...data.data.map((row) =>
        csvHeaders
          .map((header) =>
            typeof row[header] === "object"
              ? JSON.stringify(row[header])
              : row[header]
          )
          .join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "data.csv");
  };

  const columns = availableColumns.map((column) => ({
    field: column,
    headerName: column,
    flex: 1,
    sortable: true,
  }));

  // Adding a serial number column
  columns.unshift({
    field: "sn",
    headerName: "SN",
    flex: 0.5,
    sortable: false,
    valueGetter: (i) => (currentPage - 1) * 12  + 1,
  });

  return (
    <Box padding={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 2,
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <Box flexGrow={1}></Box>
        <FormControl
          sx={{ minWidth: { xs: "100%", md: "150px" } }}
          size="small"
        >
          <InputLabel>Search Column</InputLabel>
          <Select
            value={searchColumn}
            onChange={(e) => setSearchColumn(e.target.value)}
          >
            {availableColumns.map((column, index) => (
              <MenuItem key={index} value={column}>
                {column}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          sx={{ minWidth: { xs: "100%", md: "150px" } }}
          size="small"
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Search
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDownloadCSV}
        >
          Download CSV
        </Button>
      </Box>

      {/* DataGrid */}
      <Box
        sx={{
          height: "60vh",
          width: "100%",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            rows={data ? data.data : []}
            columns={columns}
            pageSize={12}
            rowsPerPageOptions={[12]}
            paginationMode="server"
            rowCount={data ? data.total : 0}
            onPageChange={(params) => setCurrentPage(params.page + 1)}
            onRowClick={handleRowClick}
            getRowId={(row) => row?.id || row?.ID}
          />
        )}
      </Box>
      <Divider />

      {/* Dialog for Row Details */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Data Details</DialogTitle>
        <Divider />
        <DialogContent sx={{ maxWidth: "70vw", width: "100%" }}>
          {selectedRow && (
            <Box
              sx={{
                overflowX: "auto",
                maxHeight: "60vh",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">Key</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">Value</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(selectedRow).map(([key, value], index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="body2">{key}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {typeof value === "object"
                            ? JSON.stringify(value)
                            : value}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
