import React, { useState, useEffect } from "react";
import { Box, Card, Chip, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TopItem } from "../home/TopItem";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts";
import { useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ArrowForwardIos, LineAxis } from "@mui/icons-material";

const types = [{ label: "Leakage", color: "#48CFCB" }];

function NRWDashboard() {
  const [stats, setStats] = useState(null);
  const [chartsData, setChartsData] = useState({});
  const [dmaDistribution, setDmaDistribution] = useState(null);

  useEffect(() => {
    // Fetch stats for TopItems
    fetch(`/api/nrw_leakages/stats`)
      .then((res) =>
        res.ok ? res.json() : Promise.reject("Failed to fetch data")
      )
      .then((data) => setStats(data))
      .catch((e) => console.error("Error:", e));

    // Fetch data for PieCharts and BarCharts for each type
    types.forEach((type) => {
      fetch(`/api/nrw_leakages/charts/status/${type.label}`)
        .then((res) => res.json())
        .then((data) => {
          setChartsData((prevData) => ({
            ...prevData,
            [type.label]: { pieData: data },
          }));
        })
        .catch(console.error);

      fetch(`/api/nrw_leakages/charts/monthly/${type.label}`)
        .then((res) => res.json())
        .then((data) => {
          setChartsData((prevData) => ({
            ...prevData,
            [type.label]: { ...prevData[type.label], barData: data },
          }));
        })
        .catch(console.error);
    });
  }, [types]);

  useEffect(() => {
    fetch(`/api/nrw_leakages/dma/distibution`)
      .then((res) =>
        res.ok ? res.json() : Promise.reject("Could not fetch data!!!")
      )
      .then((data) => setDmaDistribution(data))
      .catch(console.error);
  }, []);

  const navigate = useNavigate();

  return (
    <Box marginTop={1} padding={1}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={2.4} onClick={() => navigate("/nrw/management")}>
          <CardItem title="Total" color="#8B5DFF" value={stats?.Total || 0} />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <CardItem
            title="Received"
            color="#FF8000"
            value={stats?.Received || 0}
          />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <CardItem
            title="Assigned"
            color="#3D3BF3"
            value={stats?.Assigned || 0}
          />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <CardItem
            title="Resolved"
            color="#FF8000"
            value={stats?.Resolved || 0}
          />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <CardItem
            title="Not Resolved"
            color="#219B9D"
            value={stats?.NotResolved || 0}
          />
        </Grid>
      </Grid>
      {types.map((type, index) => {
        const pieData = chartsData[type.label]?.pieData || [];
        const barData = chartsData[type.label]?.barData || [];
        const barXAxisData = barData.map((item) => item.name);
        const barSeriesData = barData.map((item) => item.value);

        return (
          <Grid container spacing={3} key={index}>
            <Grid item xs={12}>
              <Card
                sx={{
                  display: "grid",
                  gridTemplateColumns: "auto 1fr auto",
                  boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
                }}
              >
                <Box sx={{ width: "16px", backgroundColor: type.color }}></Box>
                <Typography p={1} color="primary" variant="h6">
                  {type.label}
                </Typography>
                <Chip
                  label={stats?.[type.label] || 0}
                  sx={{
                    margin: "auto 10px auto 0",
                    backgroundColor: type.color,
                    color: "white",
                  }}
                />
              </Card>
            </Grid>
            <Grid item md={5} xs={12}>
              <Card
                sx={{
                  borderRadius: "12px",
                  boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 2,
                }}
              >
                <PieChart
                  series={[
                    {
                      data: pieData.length
                        ? pieData
                        : [{ name: "No Data", value: 0 }],
                      highlightScope: { fade: "global", highlight: "item" },
                      innerRadius: 20,
                      outerRadius: "80%",
                    },
                  ]}
                />
              </Card>
            </Grid>
            <Grid item md={7} xs={12}>
              <Card
                sx={{
                  borderRadius: "12px",
                  boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
                }}
              >
                <BarChart
                  title="Monthly Distribution"
                  xAxis={[
                    {
                      scaleType: "band",
                      data: barXAxisData.length ? barXAxisData : [""],
                    },
                  ]}
                  series={[
                    { data: barSeriesData.length ? barSeriesData : [0] },
                  ]}
                  height={300}
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card
                sx={{
                  display: "grid",
                  gridTemplateColumns: "auto 1fr",
                  boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
                }}
              >
                <Box sx={{ width: "16px", backgroundColor: "#48CFCB" }}></Box>
                <Typography p={1} color="primary" variant="h6">
                  Distribution by DMA
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card
                sx={{
                  borderRadius: "8px",
                  boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
                }}
              >
                <BarChart
                  title="DMA Distribution"
                  xAxis={[
                    {
                      scaleType: "band",
                      data: dmaDistribution?.Dist?.map((item) => item.name) || [
                        "",
                      ],
                    },
                  ]}
                  series={[
                    {
                      data: dmaDistribution?.Dist?.map(
                        (item) => item.value
                      ) || [0],
                    },
                  ]}
                  height={500}
                />
              </Card>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
}

export default NRWDashboard;

const CardItem = ({ title, value, color, current, last, positive }) => {
  return (
    <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
      <Card
        sx={{
          p: 2,
          borderRadius: 3,
          backgroundColor: color,
          color: "white",
          boxShadow: "0px 4px 8px #60606020",
        }}
      >
        <Stack spacing={1}>
          <Box display="flex" gap={1}>
            <Typography flexGrow={1}>{title}</Typography>
            <LineAxis sx={{ fontSize: "2em" }} />
          </Box>
          <Typography
            variant="title"
            sx={{ fontWeight: 500, fontSize: "1.5rem" }}
          >
            {(value - 0).toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>
          <Box
            title={`Last Month: ${last}\nThis Month: ${current}`}
            display="flex"
            gap={1}
          >
            <Typography flexGrow={1} sx={{ fontSize: "small" }}>
              vs last month
            </Typography>
            <Typography
              sx={{
                color: `${current >= last ? "white" : "red"}`,
                fontSize: "small",
              }}
            >
              {current + last > 0
                ? ((current / (current + last)) * 100).toFixed(1)
                : "0.0"}
              %
            </Typography>
            <ArrowForwardIos
              sx={{
                color: `${current >= last ? "white" : "red"}`,
                rotate: `${current >= last ? "-" : "+"}90deg`,
                fontSize: "small",
                margin: "auto",
                display: "block",
              }}
            />
          </Box>
        </Stack>
      </Card>
    </Grid2>
  );
};
