import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Select,
  MenuItem,
  Card,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Typography,
  Grid,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ArrowForwardIos, Download, LineAxis } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { BarChart, LineChart } from "recharts";

export default function DMAAnalysis2() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dmas, setDMAs] = useState("All");
  const [type, setType] = useState("All");
  const today = new Date();
  const [startDate, setStartDate] = useState("2024-09-01");
  const [endDate, setEndDate] = useState(today.toISOString().split("T")[0]);
  const [stats, setStats] = useState(null);

  let selType = type !== "All" ? `${type}` : "All";
  let selDma = dmas !== "All" ? `${dmas}` : "All";

  useEffect(() => {
    setLoading(true);
    fetch(`/api/nrwreading/dma/stats/${selDma}/${startDate}/${endDate}`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        setStats(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [dmas, startDate, endDate]);

  const fetchData = () => {
    setLoading(true);
    fetch(`/api/nrwreading/${selDma}/${selType}/${startDate}/${endDate}`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [dmas, type, startDate, endDate]);

  const columns = [
    { field: "sn", headerName: "SN", flex: 0.5, sortable: false },
    { field: "DMAName", headerName: "DMA", flex: 1 },
    { field: "MeterType", headerName: "Meter Type", flex: 1 },
    { field: "AccountNo", headerName: "Account", flex: 1 },
    { field: "FirstReading", headerName: "First Reading", flex: 1 },
    { field: "SecondReading", headerName: "Second Reading", flex: 1 },
    { field: "Consumption", headerName: "Consumption", flex: 1 },
  ];

  const rows =
    data && data?.data
      ? data?.data?.map((item, index) => ({
          id: index,
          sn: index + 1,
          DMAName: item.DMAName,
          MeterType: item.MeterType,
          AccountNo: item.AccountNo,
          FirstReading: item.FirstReading,
          SecondReading: item.SecondReading,
          Consumption: Number(item.SecondReading) - Number(item.FirstReading),
        }))
      : [];

  const downloadCSV = () => {
    if (!data || !data.data) return;

    const headers = [
      "SN",
      "DMA",
      "Meter Type",
      "Account",
      "FirstReadingDate",
      "SecondReadingDate",
      "First Reading",
      "Second Reading",
      "Consumption",
    ];
    const rows = data?.data?.map((item, index) => [
      index + 1,
      item.DMAName,
      item.MeterType,
      item.AccountNo,
      item.FirstReadingDate,
      item.SecondReadingDate,
      item.FirstReading, // Replace with correct data
      item.SecondReading, // Replace with correct data
      Number(item.SecondReading) - Number(item.FirstReading), // Replace with correct calculation if available
    ]);

    let csvContent =
      "data:text/csv;charset=utf-8," +
      [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "dma_analysis_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box>
      <Stack spacing={3}>
        <Box
          display="flex"
          gap={3}
          justifyContent="flex-end"
          alignItems="center"
        >
          <FormControl size="small">
            <InputLabel>DMA</InputLabel>
            <Select
              value={dmas}
              onChange={(e) => setDMAs(e.target.value)}
              displayEmpty
              label="DMA"
            >
              {[
                "All",
                "Kamiti A",
                "Kamiti B",
                "Kanjata",
                "Kiu River",
                "Kiu Kenda",
                "Kiambu Golf Club",
                "Makanja 1",
                "Makanja 2",
                "Samaki 1",
                "Samaki 2",
              ].map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl size="small">
            <InputLabel>Meter Type</InputLabel>
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}
              displayEmpty
              label="Activity Type"
            >
              {["All", "Master Meter", "Customer Meter"].map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            value={startDate}
            label="First Reading Date"
            type="date"
            size="small"
            onChange={(e) => setStartDate(e.target.value)}
          />
          <TextField
            value={endDate}
            label="Second Reading Date"
            type="date"
            size="small"
            onChange={(e) => setEndDate(e.target.value)}
          />
          <Button color="secondary" variant="contained" onClick={downloadCSV}>
            <Download />
          </Button>
        </Box>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={2.4}>
              <CardItem
                title="Total Customers"
                color="#219B9D"
                value={stats?.Customers || 0}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <CardItem
                title="Customer Cons"
                color="#8B5DFF"
                value={stats?.Cust_Cons || 0}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <CardItem
                title="Mastermeter Cons"
                color="#219B9D"
                value={stats?.Mast_Cons || 0}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <CardItem
                title="NRW Volume"
                color="#FF8000"
                value={(stats?.Mast_Cons || 0) - (stats?.Cust_Cons || 0)}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <CardItem
                title="NRW Ratio"
                color="#3D3BF3"
                value={
                  stats?.Mast_Cons
                    ? (((stats?.Mast_Cons || 0) - (stats?.Cust_Cons || 0)) /
                        stats?.Mast_Cons) *
                      100
                    : 0
                }
              />
            </Grid>
          </Grid>
        </Box>

        <Card
          style={{
            borderRadius: "10px",
            boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ height: "60vh", width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={12}
                rowsPerPageOptions={[12]}
                pagination
              />
            </Box>
          )}
        </Card>
      </Stack>
    </Box>
  );
}

const CardItem = ({ title, value, color, current, last, positive }) => {
  return (
    <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
      <Card
        sx={{
          p: 2,
          borderRadius: 3,
          backgroundColor: color,
          color: "white",
          boxShadow: "0px 4px 8px #60606020",
        }}
      >
        <Stack spacing={1}>
          <Box display="flex" gap={1}>
            <Typography flexGrow={1} sx={{ fontSize: "small" }}>
              {title}
            </Typography>
          </Box>
          <Typography
            variant="title"
            sx={{ fontWeight: 500, fontSize: "1.5rem" }}
          >
            {(value - 0).toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>
        </Stack>
      </Card>
    </Grid2>
  );
};
