import { useState, useEffect } from "react";
import { Box, Card, CircularProgress, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export default function DMAReadingsAnalysis(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 12,
  });

  useEffect(() => {
    fetchData();
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    props?.startDate,
    props?.endDate,
  ]);

  const fetchData = () => {
    setLoading(true);
    const apiUrl = `/api/dmareading/analysis/${props?.startDate}/${props?.endDate}`;
    fetch(apiUrl, { method: "GET", credentials: "include" })
      .then((res) => {
        if (!res.ok) throw new Error("Could not fetch data!!!");
        return res.json();
      })
      .then((response) => {
        setData(response?.data || []);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Error fetching data:", err);
      });
  };

  const renderColumns = () => {
    if (data?.length === 0) return [];
    const dmaNames = [...new Set(data.map((item) => item.DMAName))];

    return [
      { field: "Date", headerName: "Date", width: 150 },
      ...dmaNames.flatMap((name) => [
        {
          field: `${name}_Units`,
          headerName: `${name} Units`,
          width: 150,
        },
        {
          field: `${name}_Consumption`,
          headerName: `${name} Consumption`,
          width: 150,
        },
      ]),
    ];
  };

  const renderRows = () => {
    if (data.length === 0) return [];
    const uniqueDates = [...new Set(data.map((item) => item.Date))];
    const dmaNames = [...new Set(data.map((item) => item.DMAName))];

    return uniqueDates.map((date) => {
      const row = { id: date, Date: date };

      dmaNames.forEach((dmaName) => {
        const entry = data.find(
          (item) => item.Date === date && item.DMAName === dmaName
        );
        row[`${dmaName}_Units`] = entry ? entry.Units : "N/R";
        row[`${dmaName}_Consumption`] = entry ? entry.Consumption : "N/R";
      });

      return row;
    });
  };

  return (
    <Box marginTop={1}>
      <Stack spacing={3}>
        <Card
          style={{
            borderRadius: "10px",
            boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            overflow: "hidden", // Prevent spill outside the card
          }}
        >
          <Box
            sx={{
              height: 500, // Confine table height
              width: "100%", // Allow horizontal responsiveness
              overflowY: "auto", // Enable vertical scrolling
              overflowX: "auto", // Enable horizontal scrolling
            }}
          >
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <DataGrid
                rows={renderRows()}
                columns={renderColumns()}
                pageSize={paginationModel.pageSize}
                rowsPerPageOptions={[12, 24, 50]}
                pagination
                paginationMode="server"
                rowCount={data?.total || 0}
                onPaginationModelChange={(model) => setPaginationModel(model)}
                paginationModel={paginationModel}
                loading={loading}
                disableColumnMenu // Optional: Hide column menu for simplicity
                disableExtendRowFullWidth={false} // Ensure row width matches content
              />
            )}
          </Box>
        </Card>
      </Stack>
    </Box>
  );
}
