import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Select,
  MenuItem,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@mui/material";
import MyPagination from "../Pagination";
import { Download } from "@mui/icons-material";

export default function Metering2() {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [dmas, setDMAs] = useState("All");
  const [type, setType] = useState("All");
  const today = new Date();
  const [startDate, setStartDate] = useState(today.toISOString().split("T")[0]);
  const [endDate, setEndDate] = useState(today.toISOString().split("T")[0]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  const fetchData = () => {
    setLoading(true);
    console.log("Fetching data with new dates:", { startDate, endDate });
    setData(null);

    let selType = type !== "All" ? `${type}` : "All";

    let selDma = dmas !== "All" ? `${dmas}` : "All";

    // Log start and end date for debugging
    console.log("Fetching data with:", { selDma, selType, startDate, endDate });

    fetch(
      `/api/intervention/${selDma}/${selType}/${startDate}/${endDate}/${
        (currentPage - 1) * 12
      }`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);

        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, dmas, type, startDate, endDate]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedReport(null);
  };

  const renderTableHeaders = () => (
    <>
      <TableCell>SN</TableCell>
      <TableCell>Activity</TableCell>
      <TableCell>DMA</TableCell>
      <TableCell>Account</TableCell>
      <TableCell>Date</TableCell>
      <TableCell>Staff</TableCell>
    </>
  );

  const renderTableRows = () => {
    if (!data || !data.data) return null;

    return data.data.map((intervention, index) => (
      <TableRow
        onClick={() => handleDialogOpen(intervention)}
        sx={{ cursor: "pointer" }}
        key={intervention.ID}
      >
        <TableCell>
          <Chip label={(currentPage - 1) * 12 + index + 1} />
        </TableCell>
        <TableCell>{intervention.MeterActivity}</TableCell>
        <TableCell>{intervention.DMAName}</TableCell>
        <TableCell>
          <Chip label={intervention.AccountNo} />
        </TableCell>
        <TableCell>{intervention.Date}</TableCell>
        <TableCell>{intervention.User}</TableCell>
      </TableRow>
    ));
  };

  const downloadCSV = () => {
    if (!data || !data.data) return;

    const headers = ["SN", "Activity", "DMA", "Account", "Date", "Staff"];
    const rows = data.data.map((intervention, index) => [
      (currentPage - 1) * 12 + index + 1,
      intervention.MeterActivity,
      intervention.DMAName,
      intervention.AccountNo,
      intervention.Date,
      intervention.User,
    ]);

    let csvContent =
      "data:text/csv;charset=utf-8," +
      [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "intervention_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDialogOpen = (report) => {
    setSelectedReport(report);
    setDialogOpen(true);
  };

  return (
    <Box marginTop={1}>
      <Stack spacing={3}>
        <Box
          display="flex"
          gap={3}
          justifyContent="flex-end"
          alignItems="center"
        >
          <FormControl size="small">
            <InputLabel>DMA</InputLabel>
            <Select
              value={dmas}
              onChange={(e) => setDMAs(e.target.value)}
              displayEmpty
              label="DMA"
            >
              {[
                "All",
                "Kamiti A",
                "Kamiti B",
                "Kanjata",
                "Kiu River",
                "Kiu Kenda",
                "Kiambu Golf Club",
                "Makanja 1",
                "Makanja 2",
                "Samaki 1",
                "Samaki 2",
              ].map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl size="small">
            <InputLabel>Activity Type</InputLabel>
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}
              displayEmpty
              label="Activity Type"
            >
              {[
                "All",
                "Meter Testing",
                "Meter Servicing",
                "Meter Re-alignment",
                "Meter Relocation",
                "Meter Replacement",
                "Meter Inspection",
              ].map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            value={startDate}
            label="Start Date"
            type="date"
            size="small"
            onChange={(e) => {
              setStartDate(e.target.value);
              console.log("Updated Start Date:", e.target.value);
            }}
          />
          <TextField
            value={endDate}
            label="End Date"
            type="date"
            size="small"
            onChange={(e) => {
              setEndDate(e.target.value);
              console.log("Updated end Date:", e.target.value);
            }}
          />
          <Button color="secondary" variant="contained" onClick={downloadCSV}>
            <Download />
          </Button>
        </Box>

        <Card
          style={{
            borderRadius: "10px",
            boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box sx={{ overflowX: "auto", minHeight: "50vh" }}>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Table sx={{ minWidth: "800px" }}>
                <TableHead>
                  <TableRow>{renderTableHeaders()}</TableRow>
                </TableHead>
                <TableBody>{renderTableRows()}</TableBody>
              </Table>
            )}
          </Box>
          <Divider />
          <MyPagination
            totalPages={data ? Math.ceil(data.total / 12) : 0}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
          />
        </Card>
      </Stack>
      <ReportDetails
        report={selectedReport}
        dialogOpen={dialogOpen}
        handleDialogClose={handleDialogClose}
      />
    </Box>
  );
}

const ReportDetails = (props) => {
  const { report, dialogOpen, handleDialogClose } = props;
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false); // State to handle update dialog
  const [refresh, setRefresh] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (report) {
      setSelectedReport(report);
      fetch(`/api/intervention/${report.ID}`)
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch data!!!");
          } else {
            return res.json();
          }
        })
        .then((data) => {
          setSelectedReport(data);
        })
        .catch((err) => {});
    }
  }, [refresh, report]);

  useEffect(() => {
    if (selectedReport && selectedReport.NRWUserID) {
      fetch(`/api/mobile/${selectedReport.NRWUserID}`)
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch data!!!");
          } else {
            return res.json();
          }
        })
        .then((data) => {
          setUser(data);
        })
        .catch((err) => {});
    }
  }, [selectedReport]);

  const handleAssignStaffClick = () => {
    setAssignDialogOpen(true);
  };

  const handleAssignDialogClose = () => {
    setAssignDialogOpen(false);
    setRefresh((prev) => !prev);
  };

  const handleUpdateStatusClick = () => {
    setUpdateDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    setUpdateDialogOpen(false);
    setRefresh((prev) => !prev);
  };

  return (
    <>
      {selectedReport && (
        <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth>
          <DialogTitle sx={{ display: "flex" }}>
            <Chip label={selectedReport.MeterActivity} />
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box>
              <img
                style={{
                  width: "100%",
                  height: "250px",
                  objectFit: "cover",
                  border: "1px solid #60606050",
                  boxShadow: "0px 4px 8px #60606030",
                  borderRadius: "8px",
                }}
                src={"/api/uploads/" + selectedReport.ActivityPhoto}
                alt=""
              />
              <Typography variant="body2">
                <span style={{ fontWeight: "bold" }}>DMA:</span>
                {selectedReport.DMAName}
              </Typography>
              <Typography variant="body2">
                <span style={{ fontWeight: "bold" }}> Account No:</span>
                {selectedReport.AccountNo}
              </Typography>
              <Typography variant="body2">
                <span style={{ fontWeight: "bold" }}> Date:</span>
                {new Date(selectedReport.createdAt).toLocaleDateString()}
              </Typography>
              <Typography variant="body2">
                <span style={{ fontWeight: "bold" }}> Time:</span>

                {new Date(selectedReport.createdAt).toLocaleTimeString()}
              </Typography>
              <Typography
                sx={{ fontSize: "medium", marginTop: 1 }}
                variant="h6"
              >
                Staff
              </Typography>
              <Divider />
              <Box>
                <Typography variant="body2">
                  Name: {selectedReport.User}
                </Typography>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              variant="outlined"
              color="secondary"
            >
              Close
            </Button>

            {selectedReport?.AfterPhoto && (
              <Button
                onClick={handleUpdateStatusClick}
                variant="contained"
                color="primary"
              >
                {selectedReport?.MeterActivity === "Meter Replacement"
                  ? "Meter Photos"
                  : selectedReport?.MeterActivity === "Meter Relocation"
                  ? "Photo After Relocation"
                  : selectedReport?.MeterActivity === "Meter Re-alignment"
                  ? "Photo After Re-alignment"
                  : ""}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      <UpdateStatusDialog
        open={updateDialogOpen}
        onClose={handleUpdateDialogClose}
        image={selectedReport?.AfterPhoto}
      />
    </>
  );
};

const UpdateStatusDialog = ({ open, onClose, image }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Photo</DialogTitle>
      <Divider />
      <DialogContent>
        <Box>
          <img
            style={{
              width: "100%",
              height: "250px",
              objectFit: "cover",
              border: "1px solid #60606050",
              boxShadow: "0px 4px 8px #60606030",
              borderRadius: "8px",
            }}
            src={"/api/uploads/" + image}
            alt=""
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
