import React, { useState, useEffect } from "react";
import {
  ButtonGroup,
  Button,
  Box,
  Card,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import Data from "../components/Data/Data";

const selectList = {
  "Water Network": [
    {
      label: "Customers",
      url: "customers",
    },
    {
      label: "Tanks",
      url: "tanks",
    },
    {
      label: "Valves",
      url: "valves",
    },
    {
      label: "Bulk Meters",
      url: "bulkmeters",
    },
    {
      label: "Master Meters",
      url: "mastermeters",
    },
    {
      label: "Offtakers",
      url: "offtakers",
    },
    {
      label: "Water Pipes",
      url: "waterpipes",
    },
  ],
  "Sewer Network": [
    {
      label: "Connection Chambers",
      url: "connectionchamber",
    },
    {
      label: "Customer Chambers",
      url: "customerchamber",
    },
    {
      label: "Manholes",
      url: "manholes",
    },
    {
      label: "Sewer Lines",
      url: "sewerlines",
    },
  ],
  "New Connections": [
    {
      label: "New Water Connections",
      url: "water",
    },
    {
      label: "New Sanitation Connections",
      url: "sanitation",
    },
    {
      label: "New Consumer Line Connection",
      url: "consumerline",
    },
    {
      label: "New Customer Line Connection",
      url: "customerline",
    },
  ],
};

export default function AllData() {
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("Water Network");
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    // Automatically select the first network item when the category changes
    if (selectedCategory && selectList[selectedCategory].length > 0) {
      setSelectedNetwork(selectList[selectedCategory][0]);
    }
  }, [selectedCategory]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleNetworkChange = (network) => {
    setSelectedNetwork(network);
  };

const handleTabChange = (event, newValue) => {
  setSelectedTab(newValue);
  const selectedCategoryKey = Object.keys(selectList)[newValue];
  handleCategoryChange(selectedCategoryKey);
};


  return (
    <Box sx={{ marginTop: 7 }} disableGutters>
      <Card
        sx={{
          p: 2,
          borderRadius: 5,
          boxShadow: "0px 3px 16px #60606030",
          minHeight: "90vh",
          display: "grid",
          gridTemplateRows: "auto auto 1fr",
        }}
      >
        {/* Category Button Group */}
        <Box
          variant="contained"
          sx={{
            boxShadow: "none",
            marginBottom: 2,
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            height: "fit-content",
          }}
          aria-label="network toggle buttons"
        >
          <Tabs value={selectedTab} onChange={handleTabChange}>
            {Object.keys(selectList).map((category, i) => (
              <Tab
                key={i}
                sx={{ textTransform: "capitalize", height: "fit-content" }}
                label={category}
              />
            ))}
          </Tabs>
        </Box>

        {/* Network Button Group */}
        {selectedCategory && (
          <>
            <Box
              variant="contained"
              sx={{
                boxShadow: "none",
                marginBottom: 2,
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                height: "fit-content",
              }}
              aria-label="network toggle buttons"
            >
              {selectList[selectedCategory].map((item, i) => (
                <Button
                  key={i}
                  onClick={() => handleNetworkChange(item)}
                  variant={
                    selectedNetwork?.label === item.label
                      ? "contained"
                      : "outlined"
                  }
                  sx={{ textTransform: "capitalize", height: "fit-content" }}
                >
                  {item.label}
                </Button>
              ))}
            </Box>
          </>
        )}

        {/* Data Table */}
        <Box>
          {selectedNetwork && <Data url={selectedNetwork.url} />}
        </Box>
      </Card>
    </Box>
  );
}
